import MobileHeader from '@src/components/MobileHeader';
import PlayLayout from '@src/components/PlayLayout';
import LeftIcon from '@assets/icons/leading-icn.svg';
import ChevronRightIcon from '@assets/icons/chevron-right.svg';
import SearchIcon from '@assets/icons/search.svg';
import AlertIcon from '@assets/icons/exclamation-mark-filled.svg';
import { navigate } from 'gatsby';
import { styled } from 'stitches.config';
import Input from '@src/components/Input';
import Toggle from '@src/components/Toggle';
import React, { forwardRef } from 'react';
import Sheet from 'react-modal-sheet';
import WheelPicker from 'react-simple-wheel-picker';
import SheetHeader from '@src/components/Playroom/SheetHeader';
import { useGetProjectsQuery } from '@src/apis/queries';
import {
  usePostCreateRoomMutation,
  usePostExitRoomMutation,
  usePostModifyRoomStatusMutation,
} from '@src/apis/mutation';
import { getLocalStorage } from '@src/utils/localStorage';
import { usePlayroomContext } from '@src/providers/Playroom';
import { useAgora } from '@src/hooks/useAgora';
import { Controller, useForm } from 'react-hook-form';
import { Top10Projects } from '@src/constants/project';
import { useInView } from 'react-cool-inview';
import { ErrorModal } from '@src/components/Modal/play/ErrorModal';
import Modal, { useModal } from '@src/components/Modal';

export default function CreatePlayRoom() {
  const userData = getLocalStorage('userData') || '';
  const username = userData ? JSON.parse(userData).name : '';
  const defaultTitle = `${username}님의 방`;
  const { openSheet } = usePlayroomContext();
  const {
    agoraContextData: { handleJoinOrLeave, channel, setChannel, isConnected },
  } = useAgora();

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const { openModal, closeModal, modal } = useModal();

  const [isPrivate, setIsPrivate] = React.useState(false);
  const [isUserCountSheetOpen, setIsUserCountSheetOpen] = React.useState(false);
  const [isProjectSearchSheetOpen, setIsProjectSearchSheetOpen] =
    React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');

  const [selectedUserCount, setSelectedUserCount] = React.useState<
    number | undefined
  >(undefined);
  const [selectedProject, setSelectedProject] = React.useState({
    id: '',
    name: '',
  });

  const selectedUserCountRef = React.useRef<number | undefined>(
    selectedUserCount
  );
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const { data: projects, fetchNextPage } = useGetProjectsQuery({
    keyword,
    enabled: Boolean(keyword),
  });
  const { mutateAsync: createRoom } = usePostCreateRoomMutation();
  const { mutateAsync: changeRoomStatus } = usePostModifyRoomStatusMutation();
  const { mutateAsync: exitRoom } = usePostExitRoomMutation();

  const { observe } = useInView({
    onEnter: ({ unobserve }) => {
      unobserve();

      fetchNextPage();
    },
  });

  const hasProjectsSearchData = React.useMemo(() => {
    return (
      projects?.pages.map((page) => page.projects).flat().length !== 0 &&
      keyword
    );
  }, [projects, keyword]);

  const handlePrevClick = () => {
    navigate(-1);
  };

  const handleUserCountChangeClick = () => {
    setSelectedUserCount(selectedUserCountRef.current);

    setIsUserCountSheetOpen(false);
  };

  const handleClose = () => {
    setIsUserCountSheetOpen(false);
  };

  const handleProjectSelect = (id: string, name: string) => {
    setSelectedProject({
      id,
      name,
    });

    setIsProjectSearchSheetOpen(false);
  };

  const handleSubmitClick = async () => {
    handleSubmit(async (formData) => {
      try {
        if (selectedUserCount === undefined || !selectedProject.id) {
          setIsToastOpen(true);

          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
          return;
        }

        const createRoomResponse = await createRoom({
          title: formData.title || defaultTitle,
          maxUserCount: selectedUserCount,
          password: formData.password || undefined,
          projectId: selectedProject.id,
        });

        if (createRoomResponse.success) {
          if (createRoomResponse.data?.id) {
            handleJoinOrLeave(createRoomResponse.data.id);
          }
        }
      } catch (e) {
        openModal(
          <ErrorModal
            onClose={closeModal}
            onConfirm={async () => {
              await exitRoom();

              closeModal();
            }}
          />
        );
      }
    })();
  };

  React.useEffect(() => {
    const changeState = async () => {
      if (!channel) return;
      const data = await changeRoomStatus({
        roomId: channel || '',
        statusObject: {
          status: 'Waiting',
        },
      });

      if (data) {
        navigate('/play/rooms', { replace: true });
        openSheet();
      } else {
        await exitRoom();
      }
    };

    if (isConnected) {
      changeState();
    }
  }, [isConnected]);

  React.useEffect(() => {
    if (!isProjectSearchSheetOpen) {
      setKeyword('');
    }
  }, [isProjectSearchSheetOpen]);

  const userCountData = Array.from({ length: 7 }, (_, i) => ({
    id: `${i + 2}`,
    value: `${i + 2}인`,
  }));

  return (
    <>
      <PlayLayout>
        <MobileHeader
          left={
            <Button type="button" onClick={handlePrevClick}>
              <img src={LeftIcon} alt="뒤로 가기" />
            </Button>
          }
          center={<Title>플레이룸 만들기</Title>}
          customStyles={{ marginBottom: '16px' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 64px)',
            padding: '0 16px',
          }}
        >
          <div style={{ flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ display: 'inline-block', marginBottom: '8px' }}>
              플레이룸 제목
            </Label>
            <Controller
              name="title"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: '제목은 최대 20자까지 작성 가능해요.',
                },
              }}
              render={({ field }) => (
                <Input
                  placeholder={defaultTitle}
                  isError={Boolean(errors.title)}
                  {...field}
                />
              )}
            />
            {errors.title && (
              <ErrorText>
                <img
                  src={AlertIcon}
                  alt="경고 아이콘"
                  style={{ marginRight: '4px' }}
                />
                {errors.title.message as string}
              </ErrorText>
            )}
            <Content>
              <Label>플레이할 게임</Label>
              {selectedProject.name ? (
                <SelectedGame
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsProjectSearchSheetOpen(true)}
                >
                  <span style={{ verticalAlign: 'middle' }}>
                    {selectedProject.name}
                  </span>
                  <img src={ChevronRightIcon} alt="오른쪽 화살표 아이콘" />
                </SelectedGame>
              ) : (
                <GameSelectButton
                  onClick={() => setIsProjectSearchSheetOpen(true)}
                >
                  게임 선택하기
                </GameSelectButton>
              )}
            </Content>
            <Content>
              <Label>인원 수</Label>
              <MaxUserSelectButton
                onClick={() => setIsUserCountSheetOpen(true)}
              >
                {selectedUserCount ? (
                  <>
                    <span style={{ verticalAlign: 'middle' }}>
                      {selectedUserCount}인
                    </span>
                    <img src={ChevronRightIcon} alt="오른쪽 화살표 아이콘" />
                  </>
                ) : (
                  <>
                    <span style={{ verticalAlign: 'middle' }}>선택하기</span>
                    <img
                      src={ChevronRightIcon}
                      alt="오른쪽 화살표"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </>
                )}
              </MaxUserSelectButton>
            </Content>
            <Content>
              <Label>비밀번호 설정</Label>
              <Toggle onChange={() => setIsPrivate((prev) => !prev)} />
            </Content>
            {isPrivate && (
              <>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (
                        !Number.isNaN(Number(value)) &&
                        value.length === 4 &&
                        Number(value) >= 0 &&
                        Number(value) <= 9999
                      ) {
                        return true;
                      }
                      return '비밀번호는 4자리로 설정해 주세요.';
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      pattern="\d*"
                      maxLength={4}
                      isError={Boolean(errors.password)}
                      placeholder="숫자 4자리를 입력해주세요."
                      {...field}
                    />
                  )}
                />
                {errors.password && (
                  <ErrorText>
                    <img
                      src={AlertIcon}
                      alt="경고 아이콘"
                      style={{ marginRight: '4px' }}
                    />
                    {errors.password.message as string}
                  </ErrorText>
                )}
              </>
            )}
          </div>
          <CreateButtonContainer>
            <CreateButton onClick={handleSubmitClick}>
              플레이룸 만들기
            </CreateButton>
          </CreateButtonContainer>
        </div>
        <ToastContainer>
          <ToastItem isOpen={isToastOpen}>
            선택되지 않은 정보가 있어요.
          </ToastItem>
        </ToastContainer>
      </PlayLayout>
      {/* 바텀 시트 컴포넌트 영역 */}
      <CustomSheet
        isOpen={isUserCountSheetOpen}
        onClose={() => setIsUserCountSheetOpen(false)}
        snapPoints={[0.4]}
      >
        <Sheet.Container>
          <Sheet.Header>
            <CustomDragIndicatorContainer>
              <CustomDragIndicator />
            </CustomDragIndicatorContainer>
          </Sheet.Header>
          <Sheet.Content>
            <SheetHeader title="인원 수 설정" />
            <WheelPicker
              data={userCountData}
              height={200}
              selectedID="2"
              onChange={(target) => {
                selectedUserCountRef.current = Number(target.id);
              }}
              itemHeight={40}
              backgroundColor="#fff"
              color="#191919"
            />
            <ControllerContainer>
              <ControlButton
                onClick={handleClose}
                style={{ backgroundColor: '#F2F2F2' }}
              >
                닫기
              </ControlButton>
              <ControlButton
                onClick={handleUserCountChangeClick}
                style={{ backgroundColor: '#c869ff', color: '#fff' }}
              >
                확인
              </ControlButton>
            </ControllerContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsUserCountSheetOpen(false)} />
      </CustomSheet>
      <CustomSheet
        isOpen={isProjectSearchSheetOpen}
        onClose={() => setIsProjectSearchSheetOpen(false)}
        snapPoints={[0.55]}
      >
        <Sheet.Container>
          <Sheet.Content>
            <SearchInputContainer>
              <SearchInputParent ref={inputRef} setKeyword={setKeyword} />
              <SearchInputIcon
                src={SearchIcon}
                alt="검색 아이콘"
                onClick={() => {
                  setKeyword(inputRef.current?.value || '');
                }}
              />
            </SearchInputContainer>
            {hasProjectsSearchData ? (
              <GameTitle>
                {keyword}에 대한 검색 결과 {projects?.pages[0].count}개
              </GameTitle>
            ) : (
              <GameTitle>인기있는 게임 TOP 10</GameTitle>
            )}
            <GameListContainer>
              {!hasProjectsSearchData
                ? Top10Projects.map((project, index) => (
                    <div key={project.id}>
                      <GameItemParent
                        onClick={() =>
                          handleProjectSelect(
                            project?.id || '',
                            project?.name || ''
                          )
                        }
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <GameItemImage
                            src={project.imageUrl}
                            alt="프로젝트 이미지"
                          />
                          <GameItemTitle>{project.name}</GameItemTitle>
                        </div>
                        <GameItemSelectText>적용</GameItemSelectText>
                      </GameItemParent>
                      {index !== 9 && <Divider />}
                    </div>
                  ))
                : projects?.pages
                    .map((page) => page.projects)
                    .flat()
                    .map((project, index) => (
                      <>
                        <GameItemParent
                          key={project?.id}
                          ref={
                            index ===
                            projects.pages.map((page) => page.projects).flat()
                              .length -
                              1
                              ? observe
                              : undefined
                          }
                          onClick={() =>
                            handleProjectSelect(
                              project?.id || '',
                              project?.name || ''
                            )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 'calc(100% - 26px)',
                            }}
                          >
                            <GameItemImage
                              src={project?.imageUrl || ''}
                              alt="프로젝트 이미지"
                            />
                            <GameItemTitle>{project?.name}</GameItemTitle>
                          </div>
                          <GameItemSelectText>적용</GameItemSelectText>
                        </GameItemParent>
                        {index !==
                          projects?.pages.map((page) => page.projects).flat()
                            .length -
                            1 && <Divider />}
                      </>
                    ))}
            </GameListContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsProjectSearchSheetOpen(false)} />
      </CustomSheet>
      <Modal {...modal} />
    </>
  );
}

const Button = styled('button', {
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const Title = styled('h1', {
  fontSize: '18px',
  fontWeight: 700,
});

const Label = styled('span', {
  fontSize: '14px',
  fontWeight: 400,
});

const Content = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '56px',
});

const GameSelectButton = styled('button', {
  padding: '6px 16px',
  backgroundColor: '#f2f2f2',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  border: 'none',
  borderRadius: '999px',
  cursor: 'pointer',
});

const MaxUserSelectButton = styled('button', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
});

const CreateButtonContainer = styled('div', {
  width: '100%',
  padding: '8px 16px',
  borderTop: '1px solid #ebeced',
});

const CreateButton = styled('button', {
  width: '100%',
  padding: '13px 0',
  backgroundColor: '#c869ff',
  color: '#fff',
  border: 'none',
  borderRadius: '99px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  cursor: 'pointer',
});

const CustomDragIndicatorContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '20px',
});

const CustomDragIndicator = styled('div', {
  width: '32px',
  height: '4px',
  borderRadius: '2px',
  backgroundColor: '#EBECED',
});

const ControllerContainer = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '8px 16px',
  borderTop: '1px solid #ebeced',
});

const ControlButton = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '13px 69.25px',
  borderRadius: '999px',

  '&:first-of-type': {
    marginRight: '8px',
  },
});

const CustomSheet = styled(Sheet, {
  margin: '0 auto',
  maxWidth: '500px',

  '.react-modal-sheet-backdrop': {
    /* custom styles */
  },
  '.react-modal-sheet-container': {
    /* custom styles */
  },
  '.react-modal-sheet-header': {
    /* custom styles */
  },
  '.react-modal-sheet-drag-indicator': {
    /* custom styles */
  },
  '.react-modal-sheet-content': {
    /* custom styles */
    flexShrink: 1,
    padding: '24px',
  },
});

const SearchInputContainer = styled('div', {
  position: 'relative',
});

const SearchInputParent = forwardRef(function searchInput(
  { setKeyword }: { setKeyword: React.Dispatch<React.SetStateAction<string>> },
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <SearchInput
      ref={ref}
      onKeyDown={(e) => {
        if (e.target instanceof HTMLInputElement && e.key === 'Enter') {
          if (e.target.value) {
            setKeyword(e.target.value);
          }
        }
      }}
      placeholder="게임 이름, 장소, 크리에이터 검색"
    />
  );
});

const SearchInput = styled('input', {
  width: '100%',
  padding: '8px 16px',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '21.72px',
  backgroundColor: '#f4f4f4',
  borderRadius: '999px',
  border: 'none',

  '&::placeholder': {
    color: '#a9a9a9',
  },
});

const SearchInputIcon = styled('img', {
  position: 'absolute',
  top: '7px',
  right: '16px',
});

const GameTitle = styled('h2', {
  padding: '8px 0',
  marginTop: '16px',
  borderBottom: '1px solid #000',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
});

const GameListContainer = styled('div', {
  height: 'calc(100% - 57px - 37.72px)',
  paddingTop: '24px',
  overflowY: 'auto',
});

const GameItemParent = forwardRef(function GameItemParent(
  { onClick, children }: { onClick: () => void; children: React.ReactNode },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <GameItem onClick={onClick} ref={ref}>
      {children}
    </GameItem>
  );
});

const GameItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

const GameItemImage = styled('img', {
  width: '50px',
  height: '40px',
  marginRight: '8px',
  backgroundColor: '#f2f2f2',
});

const GameItemTitle = styled('div', {
  flexGrow: 1,
  flexShrink: 1,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const GameItemSelectText = styled('span', {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  color: '#c869ff',
});

const Divider = styled('div', {
  width: '100%',
  height: '1px',
  margin: '16px 0',
  backgroundColor: '#f4f4f4',
});

const ErrorText = styled('span', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#F54E54',
});

const SelectedGame = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

const ToastContainer = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  textAlign: 'center',
});

const ToastItem = styled('span', {
  display: 'inline-block',
  margin: '0 auto',
  padding: '14px 16px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  backgroundColor: '#353536',
  color: '#fff',
  borderRadius: '4px',
  transition: 'transform 0.3s, visibility 0.3s, opacity 0.3s',

  variants: {
    isOpen: {
      true: {
        transform: 'translateY(-120px)',
        visibility: 'visible',
        opacity: 1,
      },
      false: {
        transform: 'translateY(-48px)',
        visibility: 'hidden',
        opacity: 0,
      },
    },
  },
});
